.container {
    transition: transform 0.3s ease;
    /* Adjust the initial position */
    transform: translateY(0);
  }

.searchResults {
  
}

.searchResults::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 10px; 
}

.searchResults::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 10px;
}

.searchResults::-webkit-scrollbar-track {
  background: transparent;
}